// アプリユーザAPI.
import { AxiosInstance } from 'axios';
import * as _ from 'lodash';
import { MoffAPI } from './MoffAPI';
import { HomeVisitAPI } from './HomeVisit';
import { InterestCheckAPI } from './InterestCheckAPI';
import { LifeCheckAPI } from './LifeCheck';
import * as Moff from '../constants/MoffAPI';
import { AppUserActionType } from '../constants/AppUser';
import { MoffAPIActionType } from '../constants/MoffAPI';
import { createAxiosInstance } from '../constants/AWS';
import { SERVICE_ERR_MSG } from '../constants/HomeVisit';
import { errorFunc, apiRequestFunc } from '../utils/apiUtil';
export class AppUserAPI {
  /** インスタンス */
  private static _instance: AppUserAPI;
  private axiosInstance!: AxiosInstance;

  /** インスタンスの取得 */
  public static get instance(): AppUserAPI | null {
    // _inctanceが存在しない場合に、new User()を実行する。
    if (!this._instance) {
      this._instance = new AppUserAPI();

      // .envの設定
      const API_BASE_URL = String(process.env.REACT_APP_APP_USER_API_URL);
      const API_KEY = String(process.env.REACT_APP_APP_USER_API_KEY);
      if (typeof API_BASE_URL === 'undefined') {
        console.log('.envにREACT_APP_APP_USER_API_URLの設定がありません.');
        return null;
      }
      if (typeof API_KEY === 'undefined') {
        console.log('.envにREACT_APP_APP_USER_API_KEYの設定がありません.');
        return null;
      }
      const axiosInstance = createAxiosInstance(API_BASE_URL, API_KEY);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  public async getAppUserAPI(userId: string) {
    const url = `/app/${Moff.LOCOMO_TYPE}/user/${userId}`;
    return this.axiosInstance.get(url);
  }

  public async getAppUsersAPI(institutionSub: string) {
    const url = `v2/app/institution/${institutionSub}/app_id/1`;
    return this.axiosInstance.get(url);
  }

  /**
   * 機能訓練レポート・モフトレチェック の登録があるユーザか確認.
   *
   * @param {string} userId
   * @param {Function} moveToDetailPagePath
   * @returns {*}
   * @memberof AppUserAPI
   */
  public checkAppUserAPI(userId: string, moveToDetailPagePath: Function): any {
    const uniqueLogicFunc: any = async () => {
      const appuser = await this.getAppUserAPI(userId).catch((error: any) => {
        // ユニークIDが存在しないユーザーはnullを返す
        if (error.response.status === 404) {
          alert(SERVICE_ERR_MSG);
          return null;
        }
        // それ以外は普通のエラーを返す
        throw error;
      });
      if (appuser) {
        moveToDetailPagePath();
      }
      return null;
    };

    return apiRequestFunc(
      AppUserActionType.LOADING,
      AppUserActionType.SUCCESS,
      AppUserActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  /**
   * 機能訓練レポート・モフトレチェック の登録があるユーザか確認.
   *
   * @param {string} userId
   * @param {Function} moveToDetailPagePath
   * @returns {*}
   * @memberof AppUserAPI
   */
  public checkAppUsersAPI() {
    const moff = MoffAPI.instance;
    if (moff === null) {
      return errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.');
    }
    const uniqueLogicFunc = async () => {
      const institution = await moff.getInstitutionsFunc().then((response) => response.data);
      const uniqueIdsInfo = await this.getAppUsersAPI(institution.institution_sub)
        .then((response) => response.data)
        .catch((error: any) => {
          throw error;
        });
      return {
        uniqueIdsInfo,
      };
    };

    return apiRequestFunc(
      AppUserActionType.LOADING,
      AppUserActionType.SUCCESS,
      AppUserActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  /**
   * 興味関心チェック の登録があるユーザか確認.
   *
   * @param {string} userId
   * @returns {*}
   * @memberof AppUserAPI
   */
  public checkAppUserForInterestCheckAPI(userId: string): any {
    const interestCheckAPI: any = InterestCheckAPI.instance;
    if (interestCheckAPI === null) {
      return errorFunc(AppUserActionType.ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc: any = async () => {
      const appUser = await this.getAppUserAPI(userId).catch((error: any) => {
        // ユニークIDが存在しないユーザーはnullを返す
        if (error.response.status === 404) {
          alert(SERVICE_ERR_MSG);
          return null;
        }
        // それ以外は普通のエラーを返す
        throw error;
      });
      if (appUser) {
        return interestCheckAPI.getRecordCountAPI(appUser.data.unique_id).then((response: any) => {
          if (response.data.total === 0) {
            alert(SERVICE_ERR_MSG);
            return null;
          } else {
            return _.zipObject(['uniqueId', 'total'], [appUser.data.unique_id, response.data.total]);
          }
        });
      } else {
        return null;
      }
    };

    return apiRequestFunc(
      AppUserActionType.LOADING,
      AppUserActionType.SUCCESS,
      AppUserActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  /**
   * 居宅訪問 の登録があるユーザか確認.
   *
   * @param {string} userId
   * @returns {*}
   * @memberof AppUserAPI
   */
  public checkAppUserForHomeVisitAPI(userId: string): any {
    const homeVisit: any = HomeVisitAPI.instance;
    if (homeVisit === null) {
      return errorFunc(AppUserActionType.ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc: any = async () => {
      const appUser = await this.getAppUserAPI(userId).catch((error: any) => {
        // ユニークIDが存在しないユーザーはnullを返す
        if (error.response.status === 404) {
          alert(SERVICE_ERR_MSG);
          return null;
        }
        // それ以外は普通のエラーを返す
        throw error;
      });
      if (appUser) {
        return homeVisit.getRecordCountAPI(appUser.data.unique_id).then((response: any) => {
          if (response.data.total === 0) {
            alert(SERVICE_ERR_MSG);
            return null;
          } else {
            return _.zipObject(['uniqueId', 'total'], [appUser.data.unique_id, response.data.total]);
          }
        });
      } else {
        return null;
      }
    };

    return apiRequestFunc(
      AppUserActionType.LOADING,
      AppUserActionType.SUCCESS,
      AppUserActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  /**
   * 生活チェック の登録があるユーザか確認.
   *
   * @param {string} userId
   * @returns {*}
   * @memberof AppUserAPI
   */
  public checkAppUserForLifeCheckAPI(userId: string): any {
    const lifeCheck = LifeCheckAPI.instance;
    if (lifeCheck === null) {
      return errorFunc(AppUserActionType.ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc: any = async () => {
      const appUser = await this.getAppUserAPI(userId).catch((error: any) => {
        // ユニークIDが存在しないユーザーはnullを返す
        if (error.response.status === 404) {
          alert(SERVICE_ERR_MSG);
          return null;
        }
        // それ以外は普通のエラーを返す
        throw error;
      });
      if (appUser) {
        return lifeCheck.getRecordCountAPI(appUser.data.unique_id).then((response: any) => {
          if (response.data.length === 0) {
            alert(SERVICE_ERR_MSG);
            return null;
          } else {
            return _.zipObject(['uniqueId', 'total'], [appUser.data.unique_id, response.data.length]);
          }
        });
      } else {
        return null;
      }
    };

    return apiRequestFunc(
      AppUserActionType.LOADING,
      AppUserActionType.SUCCESS,
      AppUserActionType.ERROR,
      uniqueLogicFunc,
    );
  }
}

export const createAppUserAPIInstance: any = (actionType: AppUserActionType = AppUserActionType.ERROR) => {
  // Moffインスタンスが存在しない場合、エラーアクションを返す
  return AppUserAPI.instance === null
    ? [true, null, errorFunc(actionType, '不明なエラーが発生しました.')]
    : [false, AppUserAPI.instance, null];
};

// appUserを取得する関数、モフトレ以外の機能を使っていないとappUserに登録されていないため、エラーハンドリングが必要
export const getAppUserData = async (userId: string, actionType: string): Promise<any> => {
  const [isAppUserAPIError, appUserAPIInstance, appUserAPIErrorAction] = createAppUserAPIInstance(actionType);

  return isAppUserAPIError
    ? appUserAPIErrorAction
    : appUserAPIInstance.getAppUserAPI(userId).catch((error: any) => {
        // ユニークIDが存在しないユーザーはnullを返す
        if (error.response.status === 404) {
          return null;
        }
        // それ以外は普通のエラーを返す
        throw error;
      });
};
