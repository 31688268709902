// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-ignore
// @ts-ignore
import React, { FC, useRef, useState, useEffect, useLayoutEffect } from 'react';
import _, { toNumber } from 'lodash';

import * as MoffAPIActions from '../actions/MoffAPI';

import * as UserListField from '../constants/UserListField';
import * as UserBasicInfoListField from '../constants/MoffRaku';
import { MENU_TYPE } from '../constants/Menu';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-ignore
// @ts-ignore
import jspreadsheet from 'jspreadsheet-ce';
import '../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css';
import '../../node_modules/jsuites/dist/jsuites.css';
import { useDispatch, useSelector } from 'react-redux';
import store from '../store/configureStore';
import GeneralPrimaryGhostButton from '../components/Atoms/GeneralPrimaryGhostButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  spreadsheet: {},
}));

interface UserSelectBodyProps {
  users: UserListField.User[];
  type: MENU_TYPE;
  icd10: UserBasicInfoListField.ICD10[];
}

type AllState = ReturnType<typeof store.getState>;

// 利用者一覧の表のフィールド表示用コンポーネント
const UserInputMoffRakuContainer: FC<UserSelectBodyProps> = ({ users, type, icd10 }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // jspreadsheet
  const jRef = useRef(null);

  const moffAPIGlobalState = useSelector((state: AllState) => state.moffAPI);
  const [cachedUsers, setCachedUsers] = useState<UserListField.User[]>([]);
  const [usersBasicInfo, setUsersBasicInfo] = useState<UserBasicInfoListField.BasicInfoFormat[]>([]);
  const [options, setOptions] = useState({});
  const [users_table_data, setUsersTableData] = useState({});

  const INDEX_OF_BOUNDARY_FOR_EDITABLE = 17;
  //ICD10トリガー
  const INDEX_OF_ICD10_TRIGGER_1 = 26;
  const INDEX_OF_ICD10_TRIGGER_2 = 41;
  const INDEX_OF_ICD10_TRIGGER_3 = 43;
  const INDEX_OF_ICD10_TRIGGER_4 = 45;
  const INDEX_OF_ICD10_TRIGGER_5 = 47;

  const to_string_col_index = (numeric_col_index: number) => {
    const RADIX = 26;
    const A = 'A'.charCodeAt(0);
    let n = numeric_col_index;
    let s = '';
    while (n >= 1) {
      n--;
      s = String.fromCharCode(A + (n % RADIX)) + s;
      n = Math.floor(n / RADIX);
    }
    return s;
  };

  // const setICD10 = (value: any, cell: string) => {
  //   // valueが icd10のnameに該当するものがあればその配列番号のicd10がICD10コードなので、それをセルに挿入
  //   const code = icd10.find(item => {
  //     return item.name === value.toString()
  //   });
  //   if (code){
  //     setValue(cell, code.icd10);
  //   }
  // }
  // const setValue = (cell: string, value: string) => {
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //   // @ts-ignore
  //   jRef.current.jspreadsheet.setValue(cell, value);
  // }

  /** 和暦変換 **/
  const era_year = (year: string, month: string): [string, string] => {
    try {
      const date = new Date(Number(year), Number(month) - 1);
      const date_format_option = { era: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const formatter = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', date_format_option).formatToParts(date);
      const disp_era = formatter[0].value;
      const disp_year = formatter[1].value;
      return [disp_era, disp_year];
    } catch (e) {
      console.log(e.toString());
      return ['', ''];
    }
  };

  /** 和暦から西暦に変換 **/
  const era_to_year = (name: string, year: number): number => {
    if (!name || !year) return 0;
    if (isNaN(year)) return 0;
    if (year <= 0) return 0;
    const data = [
      {
        name: '令和',
        year: 31,
        startYear: 2019,
      },
      {
        name: '平成',
        year: false,
        startYear: 1988,
      },
      {
        name: '昭和',
        year: 64,
        startYear: 1925,
      },
      {
        name: '大正',
        year: 15,
        startYear: 1911,
      },
      {
        name: '明治',
        year: 45,
        startYear: 1867,
      },
    ];
    for (let i = 0, len = data.length; i < len; i++) {
      if (name === data[i].name && (data[i].year === false || year <= data[i].year)) {
        const startYear = toNumber(data[i].startYear);
        return startYear + year;
      }
    }
    return 0;
  };

  const saveAllData = async (data: any) => {
    // 保存するデータ・フォーマットに変換 フォーマットは MoffRakuConst.BasicInfoFormat
    const data_array = [...data];
    const data_json_array = data_array.map((row) => {
      return {
        user_id: row[0],
        user_name: row[1],
        user_name_kana: row[2],
        user_gender: row[3],
        user_birth_year: era_to_year(row[4], toNumber(row[5])),
        user_birth_month: row[6],
        user_birth_day: row[7],
        user_care_level: row[8],
        user_care_id: row[9],
        user_visit_day: [row[10], row[11], row[12], row[13], row[14], row[15], row[16]],
        user_insurer_number: row[17],
        planner: row[18],
        planner_job: row[19],
        program_planner: row[20],
        program_planner_job: row[21],
        independence_level_disabled_elderly: row[22],
        independence_level_dementia_elderly: row[23],
        user_wish: row[24],
        family_wish: row[25],
        disease_name: row[26],
        idc10_disease_name: row[27],
        illness_era: row[28],
        illness_year: row[29],
        illness_month: row[30],
        illness_day: row[31],
        hospitalization_era: row[32],
        hospitalization_year: row[33],
        hospitalization_month: row[34],
        hospitalization_day: row[35],
        discharge_era: row[36],
        discharge_year: row[37],
        discharge_month: row[38],
        discharge_day: row[39],
        course_of_treatment: row[40],
        complicated_diseases_1: row[41],
        complicated_diseases_icd10_1: row[42],
        complicated_diseases_2: row[43],
        complicated_diseases_icd10_2: row[44],
        complicated_diseases_3: row[45],
        complicated_diseases_icd10_3: row[46],
        complicated_diseases_4: row[47],
        complicated_diseases_icd10_4: row[48],
      };
    });
    const request_data = { data: data_json_array };
    console.log(request_data);
    // save
    if (window.confirm('保存してよろしいですか？')) {
      await dispatch(MoffAPIActions.putMoffRakuUsers(request_data));
    }
  };

  const getAllData = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const data = jRef.current.jspreadsheet.getData();
    // Object.keys(data).map((key, index) => {
    //   console.log(key, index, data[key]);
    // });
    saveAllData(data);
  };

  // const download = () => {
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //   // @ts-ignore
  //   jRef.current.jspreadsheet.download();
  // };

  useEffect(() => {
    if (!_.isEmpty(moffAPIGlobalState.result.users)) {
      //const sortedUsers = duplicateRepeatSort(USERS_SORT_KEYS, moffAPIGlobalState.result, objectQuickSort);
      setCachedUsers(moffAPIGlobalState.result.users);
    }
    if (!_.isEmpty(moffAPIGlobalState.result.usersBasicInfo)) {
      //const sortedUsers = duplicateRepeatSort(USERS_SORT_KEYS, moffAPIGlobalState.result, objectQuickSort);
      setUsersBasicInfo(moffAPIGlobalState.result.usersBasicInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.Loaded, moffAPIGlobalState.result]);

  useEffect(() => {
    // setUrlBase(type.urlBase);
    if (
      _.isEmpty(moffAPIGlobalState.result.users) &&
      _.isEmpty(moffAPIGlobalState.result.usersBasicInfo) &&
      _.isEmpty(users) &&
      type === MENU_TYPE.MoffRakuUsers
    ) {
      window.location.href = '/moff_raku_users';
    } else if (_.isEmpty(users) && type === MENU_TYPE.MoffRakuUsers) {
      alert('利用者を登録してください');
      window.location.href = '/admin.html';
    }
  }, [moffAPIGlobalState.result, type, users]);

  useLayoutEffect(() => {
    //TODO
    // userのオブジェクトの入ったhiddenのClass.
    const usersObject: HTMLCollectionOf<Element> = document.getElementsByClassName('user');
    let index = 0;
    for (const el in usersObject) {
      if (Object.prototype.hasOwnProperty.call(usersObject, el)) {
        // 1つのエレメント.
        const parent: HTMLElement | null = usersObject[el].parentElement;

        if (parent) {
          if (parent.hidden === false) {
            const isEven: boolean = index % 2 === 0;
            [].forEach.call(
              parent.childNodes,
              (child: Node) => ((child as HTMLElement).style.background = isEven ? '#fff' : '#e8e4df'),
            );
            index++;
          }
        }
      }
    }
  }, [users]);

  useEffect(() => {
    // const users_data = !cachedUsers ? [] : cachedUsers.map((user) => {
    setUsersTableData(
      !cachedUsers
        ? {}
        : cachedUsers.map((user) => {
            const user_id = user.user_id;
            const birth = era_year(user.user_birth_year, user.user_birth_month);
            const gender = user.user_gender === 0 ? '男' : '女';
            // user_care_levelの数字 要支援1,要支援2,要介護1,要介護2,要介護3,要介護4,要介護5の順に0,1,2,3,4,5,6の整数が対応する
            const care_level =
              Number(user.user_care_level) === 0
                ? '要支援1'
                : Number(user.user_care_level) === 1
                ? '要支援2'
                : Number(user.user_care_level) === 2
                ? '要介護1'
                : Number(user.user_care_level) === 3
                ? '要介護2'
                : Number(user.user_care_level) === 4
                ? '要介護3'
                : Number(user.user_care_level) === 5
                ? '要介護4'
                : Number(user.user_care_level) === 6
                ? '要介護5'
                : '';
            // user_visit_day [月,火,水,木,金,土,日]の順にサイズ7の配列が入る。
            const visit_day = user.user_visit_day ? user.user_visit_day : [0, 0, 0, 0, 0, 0, 0];

            // usersBasicinfoでuser_idが同一のものを結合
            const user_basicinfo = !usersBasicInfo
              ? undefined
              : usersBasicInfo.find((item) => item.user_id === user.user_id);
            if (user_basicinfo === undefined || user_basicinfo === null) {
              return [
                user_id,
                user.user_name,
                user.user_name_kana,
                gender,
                birth[0],
                birth[1],
                user.user_birth_month,
                user.user_birth_day,
                care_level,
                user.user_care_id,
                visit_day[0],
                visit_day[1],
                visit_day[2],
                visit_day[3],
                visit_day[4],
                visit_day[5],
                visit_day[6],
                user.user_insurer_no,
              ];
            }
            const planner = user_basicinfo.planner;
            const planner_job = user_basicinfo.planner_job;
            const program_planner = user_basicinfo.program_planner;
            const program_planner_job = user_basicinfo.program_planner_job;
            const independence_level_disabled_elderly = user_basicinfo.independence_level_disabled_elderly;
            const independence_level_dementia_elderly = user_basicinfo.independence_level_dementia_elderly;
            const user_wish = user_basicinfo.user_wish;
            const family_wish = user_basicinfo.family_wish;
            const disease_name = user_basicinfo.disease_name;
            const idc10_disease_name = user_basicinfo.idc10_disease_name;
            const illness_era = user_basicinfo.illness_era;
            const illness_year = user_basicinfo.illness_year;
            const illness_month = user_basicinfo.illness_month;
            const illness_day = user_basicinfo.illness_day;
            const hospitalization_era = user_basicinfo.hospitalization_era;
            const hospitalization_year = user_basicinfo.hospitalization_year;
            const hospitalization_month = user_basicinfo.hospitalization_month;
            const hospitalization_day = user_basicinfo.hospitalization_day;
            const discharge_era = user_basicinfo.discharge_era;
            const discharge_year = user_basicinfo.discharge_year;
            const discharge_month = user_basicinfo.discharge_month;
            const discharge_day = user_basicinfo.discharge_day;
            const course_of_treatment = user_basicinfo.course_of_treatment;
            const complicated_diseases_1 = user_basicinfo.complicated_diseases_1;
            const complicated_diseases_icd10_1 = user_basicinfo.complicated_diseases_icd10_1;
            const complicated_diseases_2 = user_basicinfo.complicated_diseases_2;
            const complicated_diseases_icd10_2 = user_basicinfo.complicated_diseases_icd10_2;
            const complicated_diseases_3 = user_basicinfo.complicated_diseases_3;
            const complicated_diseases_icd10_3 = user_basicinfo.complicated_diseases_icd10_3;
            const complicated_diseases_4 = user_basicinfo.complicated_diseases_4;
            const complicated_diseases_icd10_4 = user_basicinfo.complicated_diseases_icd10_4;

            return [
              user_id,
              user.user_name,
              user.user_name_kana,
              gender,
              birth[0],
              birth[1],
              user.user_birth_month,
              user.user_birth_day,
              care_level,
              user.user_care_id,
              visit_day[0],
              visit_day[1],
              visit_day[2],
              visit_day[3],
              visit_day[4],
              visit_day[5],
              visit_day[6],
              user.user_insurer_no,
              planner,
              planner_job,
              program_planner,
              program_planner_job,
              independence_level_disabled_elderly,
              independence_level_dementia_elderly,
              user_wish,
              family_wish,
              disease_name,
              idc10_disease_name,
              illness_era,
              illness_year,
              illness_month,
              illness_day,
              hospitalization_era,
              hospitalization_year,
              hospitalization_month,
              hospitalization_day,
              discharge_era,
              discharge_year,
              discharge_month,
              discharge_day,
              course_of_treatment,
              complicated_diseases_1,
              complicated_diseases_icd10_1,
              complicated_diseases_2,
              complicated_diseases_icd10_2,
              complicated_diseases_3,
              complicated_diseases_icd10_3,
              complicated_diseases_4,
              complicated_diseases_icd10_4,
            ];
          }),
    );
  }, [cachedUsers, usersBasicInfo]);

  useEffect(() => {
    const icd10_name = !icd10
      ? ''
      : icd10.map((item) => {
          return item.name;
        });
    const icd10_code = !icd10
      ? ''
      : icd10.map((item) => {
          return item.icd10;
        });
    setOptions({
      data: users_table_data,
      //minDimensions: [10, 10],
      tableOverflow: true,
      tableHeight: '1000px',
      tableWidth: '1000px',
      columns: [
        { type: 'hidden', title: 'user_id' },
        { type: 'text', title: '氏名', width: 100 },
        { type: 'text', title: 'ふりがな', width: 100 },
        { type: 'dropdown', title: '性別', width: 80, source: ['男', '女'] },
        { type: 'dropdown', title: '生年月日\n(和暦)', width: 80, source: ['大正', '昭和', '平成', '令和'] },
        { type: 'numeric', title: '生年月日\n(年)', width: 80, mask: '00' },
        { type: 'numeric', title: '生年月日\n(月)', width: 80, mask: '00' },
        { type: 'numeric', title: '生年月日\n(日)', width: 80, mask: '00' },
        {
          type: 'dropdown',
          title: '要介護度',
          width: 100,
          source: [
            '要支援1',
            '要支援2',
            '要介護1',
            '要介護2',
            '要介護3',
            '要介護4',
            '要介護5',
            '非該当',
            '事業対象者',
            '要支援(経過的要介護)',
          ],
        },
        { type: 'text', title: '被保険者番号', width: 100 },
        { type: 'checkbox', title: '通所\n(月)', width: 40 },
        { type: 'checkbox', title: '通所\n(火)', width: 40 },
        { type: 'checkbox', title: '通所\n(水)', width: 40 },
        { type: 'checkbox', title: '通所\n(木)', width: 40 },
        { type: 'checkbox', title: '通所\n(金)', width: 40 },
        { type: 'checkbox', title: '通所\n(土)', width: 40 },
        { type: 'checkbox', title: '通所\n(日)', width: 40 },
        { type: 'text', title: '保険者番号', width: 100 },
        { type: 'text', title: '計画書作成者', width: 100 },
        {
          type: 'dropdown',
          title: '計画書作成者\n(職種)',
          width: 100,
          source: [
            '看護師',
            '准看護師',
            '理学療法士',
            '作業療法士',
            '言語聴覚士',
            '柔道整復師',
            'あん摩マッサージ指圧師',
            'はり師・きゅう師',
            '介護福祉士',
            '医師',
            '歯科医師',
            '薬剤師',
            '保健師',
            '助産師',
            '歯科衛生士',
            '精神保健福祉士',
            '介護支援専門員',
            '社会福祉士',
            '福祉用具専門相談員',
            '管理栄養士',
            '栄養士',
            'その他',
            '不明',
          ],
          autocomplete: true,
        },
        { type: 'text', title: 'プログラム立案者', width: 100 },
        {
          type: 'dropdown',
          title: 'プログラム立案者\n(職種)',
          width: 100,
          source: [
            '看護師',
            '准看護師',
            '理学療法士',
            '作業療法士',
            '言語聴覚士',
            '柔道整復師',
            'あん摩マッサージ指圧師',
            'はり師・きゅう師',
            '介護福祉士',
            '医師',
            '歯科医師',
            '薬剤師',
            '保健師',
            '助産師',
            '歯科衛生士',
            '精神保健福祉士',
            '介護支援専門員',
            '社会福祉士',
            '福祉用具専門相談員',
            '管理栄養士',
            '栄養士',
            'その他',
            '不明',
          ],
          autocomplete: true,
        },
        {
          type: 'dropdown',
          title: '障害高齢者の\n日常生活自立度',
          width: 100,
          source: ['自立', 'J1', 'J2', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        },
        {
          type: 'dropdown',
          title: '認知症高齢者の\n日常生活自立度',
          width: 100,
          source: ['自立', 'Ⅰ', 'Ⅱa', 'Ⅱb', 'Ⅲa', 'Ⅲb', 'Ⅳ', 'M'],
        },
        { type: 'text', title: '利用者本人の希望', width: 100 },
        { type: 'text', title: '家族の希望', width: 100 },
        {
          type: 'dropdown',
          title: '病名',
          width: 100,
          source: icd10_name,
          autocomplete: true,
        },
        {
          type: 'dropdown',
          title: 'ICD10(病名)',
          width: 100,
          source: icd10_code,
          autocomplete: true,
        },
        { type: 'dropdown', title: '発症日・受傷日\n令和or平成', width: 80, source: ['平成', '令和'] },
        { type: 'numeric', title: '発症日・受傷日\n年', width: 80, mask: '00' },
        { type: 'numeric', title: '発症日・受傷日\n月', width: 80, mask: '00' },
        { type: 'numeric', title: '発症日・受傷日\n日', width: 80, mask: '00' },
        { type: 'dropdown', title: '直近の入院日\n令和or平成', width: 80, source: ['平成', '令和'] },
        { type: 'numeric', title: '直近の入院日\n年', width: 80, mask: '00' },
        { type: 'numeric', title: '直近の入院日\n月', width: 80, mask: '00' },
        { type: 'numeric', title: '直近の入院日\n日', width: 80, mask: '00' },
        { type: 'dropdown', title: '直近の退院日\n令和or平成', width: 80, source: ['平成', '令和'] },
        { type: 'numeric', title: '直近の退院日\n年', width: 80, mask: '00' },
        { type: 'numeric', title: '直近の退院日\n月', width: 80, mask: '00' },
        { type: 'numeric', title: '直近の退院日\n日', width: 80, mask: '00' },
        { type: 'text', title: '治療経過\n（手術がある場合は手術日・術式等）', width: 100 },
        {
          type: 'dropdown',
          title: '合併疾患・コントロール状態1\n（高血圧、心疾患、呼吸器疾患、糖尿病等）',
          width: 100,
          source: icd10_name,
          autocomplete: true,
        },
        {
          type: 'dropdown',
          title: '合併疾患・コントロール状態1\nICD10',
          width: 100,
          source: icd10_code,
          autocomplete: true,
        },
        {
          type: 'dropdown',
          title: '合併疾患・コントロール状態2\n（高血圧、心疾患、呼吸器疾患、糖尿病等）',
          width: 100,
          source: icd10_name,
          autocomplete: true,
        },
        {
          type: 'dropdown',
          title: '合併疾患・コントロール状態2\nICD10',
          width: 100,
          source: icd10_code,
          autocomplete: true,
        },
        {
          type: 'dropdown',
          title: '合併疾患・コントロール状態3\n（高血圧、心疾患、呼吸器疾患、糖尿病等）',
          width: 100,
          source: icd10_name,
          autocomplete: true,
        },
        {
          type: 'dropdown',
          title: '合併疾患・コントロール状態3\nICD10',
          width: 100,
          source: icd10_code,
          autocomplete: true,
        },
        {
          type: 'dropdown',
          title: '合併疾患・コントロール状態4\n（高血圧、心疾患、呼吸器疾患、糖尿病等）',
          width: 100,
          source: icd10_name,
          autocomplete: true,
        },
        {
          type: 'dropdown',
          title: '合併疾患・コントロール状態4\nICD10',
          width: 100,
          source: icd10_code,
          autocomplete: true,
        },
        // { type: 'dropdown', title: '保険者番号', width: 100 },
      ],
      onchange: (el: any, cell: any, x: number, y: number, value: any, oldValue: any) => {
        console.log({ el, cell, x, y, value, oldValue });
        // 病名リストのICD10から自動的に選択されるようにする
        x = Number(x);
        if (
          x === INDEX_OF_ICD10_TRIGGER_1 ||
          x === INDEX_OF_ICD10_TRIGGER_2 ||
          x === INDEX_OF_ICD10_TRIGGER_3 ||
          x === INDEX_OF_ICD10_TRIGGER_4 ||
          x === INDEX_OF_ICD10_TRIGGER_5
        ) {
          // 合併疾患・コントロール状態1~4 :40(AN),41(AO) ~ 46(AT),47(AU)
          // (y)行目の(x)列がICD10(病名) : x+2としているのは、xが配列番号で+1して、その右が目的列なので+1
          const indexX = Number(x) + Number(2);
          const indexY = Number(y) + Number(1);
          const cell = to_string_col_index(indexX) + indexY.toString();
          const setValue = (cell: string, value: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            jRef.current.jspreadsheet.setValue(cell, value);
          };
          const setICD10 = (value: any, cell: string) => {
            // valueが icd10のnameに該当するものがあればその配列番号のicd10がICD10コードなので、それをセルに挿入
            const code = icd10.find((item) => {
              return item.name === value.toString();
            });
            if (code) {
              setValue(cell, code.icd10);
            }
          };
          setICD10(value, cell);
        }
        // モフらく計画書以外の利用者データのセルを変更しても反映されない警告を表示
        if (x <= INDEX_OF_BOUNDARY_FOR_EDITABLE) {
          alert('この情報の変更は反映できません。\nメニューに戻り、利用者設定で変更してください。');
        }
        if (x > INDEX_OF_BOUNDARY_FOR_EDITABLE) {
          //TODO 変更があるたびに一時保存する
        }
      },
    });
  }, [users_table_data, icd10]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (!jRef.current.jspreadsheet && options.data && options.data.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      jspreadsheet(jRef.current, options);
    }
  }, [options, users_table_data]);

  return (
    <>
      {typeof users === 'undefined' ? (
        <tr>
          <th scope="row">再読み込みしてください。</th>
          <td />
          <td />
          <td />
          <td />
        </tr>
      ) : null}
      <div className={classes.spreadsheet}>
        <p>氏名～保険者番号は、この画面では変更しないようにしてください。</p>
        <p>間違って変更された場合も更新はされません。</p>
        <p>変更する場合は利用者設定画面から入力をお願いします。</p>
        <p>入力欄が選択式（▼印）の箇所は、ダブルクリックして選択肢から選んでください。</p>
        <p>病名や疾患の欄は、部分一致で絞り込みができます。</p>
        <GeneralPrimaryGhostButton onClick={getAllData} innerText={'保存する'} setCss={'width: 100px; height: 40px;'} />
        <br />
        <div ref={jRef} />
        <br />
        <GeneralPrimaryGhostButton onClick={getAllData} innerText={'保存する'} setCss={'width: 100px; height: 40px;'} />
        {/*<br />*/}
        {/*<input type="button" onClick={getAllData} value="saveMoffRakuBasicInfoData" />*/}
        {/*<br />*/}
        {/*<input type="button" onClick={download} value="Download" />*/}
      </div>
    </>
  );
};

export default UserInputMoffRakuContainer;
