import { MoffAPIActionType } from '../constants/MoffAPI';
import { createMoffAPIInstance } from '../api/MoffAPI';
import * as KirokuConst from '../constants/Kiroku';
import * as ReportConst from '../constants/Report';
import * as UserSettingConst from '../constants/UserSetting';
import * as MoffRakuConst from '../constants/MoffRaku';
import { TOGGLE_POSITION } from '../constants/Training';
import { User, DisplayPayload } from '../constants/UserListField';
import { ResponseData } from '../constants/AppUser';

const [isMoffAPIError, moffAPIInstance, moffAPIErrorAction] = createMoffAPIInstance();

// 共通
// 利用者一覧取得(1人)
export const getUser = (userId: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getUserAPI(userId);
};

// 共通
// 利用者作成(1人)
export const createUser = (params: UserSettingConst.ParamsFormat) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.createUserAPI(params);
};

// 共通
// 利用者更新(1人)
export const updateUser = (userId: string, params: UserSettingConst.ParamsFormat) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.updateUserAPI(userId, params);
};

// 共通
// 利用者削除(1人)
export const deleteUser = (userId: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.deleteUserAPI(userId);
};

// 共通
// 利用者一覧取得(複数)
export const getUsers = (): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getUsersAPI();
};

// ロコモ・ADLトレーニング
// getTraining();
// getUser(userId);
// getMonthlyData(1, userId, '2017-08');
export const getDetailData = (userId: string, month: string, togglePosition: TOGGLE_POSITION) =>
  isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getDetailDataAPI(userId, month, togglePosition);

// ロコモ・ADLトレーニング
// 長期間のトレーニングデータを取得
export const getLongTermData = (userId: string, params: string[], togglePosition: TOGGLE_POSITION) =>
  isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getLongTermDataAPI(userId, params, togglePosition);

// ロコモ・ADLトレーニング
// トレーニング前月比較用データ取得.
export const getCompareData = (
  userId: string,
  startParams: { date: string; togglePosition: TOGGLE_POSITION },
  endParams: { date: string; togglePosition: TOGGLE_POSITION },
) => (isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getCompareDataAPI(userId, startParams, endParams));

// ロコモ・ADLトレーニング
// 月間データ取得(typeは1 or 2)
// 1: ロコモ予防トレーニング・詳細用
// 2: 日常生活動作トレーニング・詳細用
export const getMonthlyData = (type: number, userId: string, yearmonth: string): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMonthlyDataAPI(type, userId, yearmonth);
};

// ロコモ・ADLトレーニング
// 月間データサイズ取得(typeは1 or 2)
// 1: ロコモ予防トレーニング・詳細用
// 2: 日常生活動作トレーニング・詳細用
export const getMonthlyDataSize = (type: number, userId: string, yearmonth: string): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMonthlyDataSizeAPI(type, userId, yearmonth);
};

// 個別機能訓練
// getTraining();
// getUser(userId);
// getFunctionalTrainingHistory(userId, '2018-02-01', '2018-02-10');
export const getTrainingDetailData = (params: any[]) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getTrainingDetailDataAPI(params);
};

// 個別機能訓練
// 新規と更新を同時に.
// postFunctionalTrainingHistory と putFunctionalTrainingHistory
export const postAndPutFunctionalTrainingHistory = (
  userId: string,
  convertedUpdateRecords: KirokuConst.KirokuRequestParamsFormat[],
) => {
  return isMoffAPIError
    ? moffAPIErrorAction
    : moffAPIInstance.postAndPutFunctionalTrainingHistoryAPI(userId, convertedUpdateRecords);
};

// 個別機能訓練
// 一括入力
export const postFunctionalTrainingAllUser = (user: any, records: any[]) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.postFunctionalTrainingAllUserAPI(user, records);
};

// 個別機能訓練
// トレーニング履歴・追加
/*
  paramsの例
    const params={
        'datetime': getCurTime(),
        'duration': "00:15:00",
        'subject': "ベンチプレス220Kg",
        'instructor': "坂本慎太郎",
        'remarks': "だいぶ仕上がってきました",
    };
*/
export const postFunctionalTrainingHistory = (userId: string, params: KirokuConst.KirokuRequestParamsFormat) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.postFunctionalTrainingHistoryAPI(userId, params);
};

// 個別機能訓練
// トレーニング履歴・変更
/*
  paramsとtime_texの例
    const params={
        'duration': "02:00:00",
        'subject': "腕立て 3回",
        'instructor': "斉木俊介",
        'remarks': "とてもよくできましたね！",
        'print': 0
    };
    const time_tex="2017-06-08 09:35 a25038e7-0842-46a5-a80e-dfb7bd23bab4";
*/
// timeTexConverted: DB内のdatetimeをconvertTimeで変換しその結果の'ux-qa'の事
export const putFunctionalTrainingHistory = (
  userId: string,
  params: KirokuConst.KirokuRequestParamsFormat,
  timeTexConverted: string,
) => {
  return isMoffAPIError
    ? moffAPIErrorAction
    : moffAPIInstance.putFunctionalTrainingHistoryAPI(userId, params, timeTexConverted);
};

// 個別機能訓練
// トレーニング履歴・削除
export const deleteFunctionalTrainingHistory = (userId: string, datetimeArr: string[]) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.deleteFunctionalTrainingHistoryAPI(userId, datetimeArr);
};

// モフトレ通信
// 月選択、以下を複数同時.
// getUser(userId);
// x12
// LocomoとADL.
export const getMoffTrainingData = (userId: string, yearMonthArr: string[], intervalTimeMs = 500) => {
  return isMoffAPIError
    ? moffAPIErrorAction
    : moffAPIInstance.getMoffTrainingDataAPI(userId, yearMonthArr, intervalTimeMs);
};

// モフトレ通信
// 一括PDF用アクション、複数ユーザーのトレーニング履歴を一括取得.
// getMonthlyData(1, userId, '2017-08'); LocomoとADL.
// getMonthlyData(2, userId, '2017-08');
export const getMoffUsersDetailData = (users: any[], yearMonth: string, intervalTimeMs = 1000) => {
  return isMoffAPIError
    ? moffAPIErrorAction
    : moffAPIInstance.getMoffUsersDetailDataAPI(users, yearMonth, intervalTimeMs);
};

// モフトレ通信
// モフトレチェックユーザー情報・施設情報を取得 [APIリクエスト]
export const getMoffCheckDetailData = (userId: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffCheckDetailDataAPI(userId);
};

// モフトレ通信
// 全ユーザーとメモを取得
export const getUsersAndPressMemos = (yearMonth: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getUsersAndPressMemosAPI(yearMonth);
};

// モフトレ通信
// 全メモを取得
export const getPressMemos = (yearMonth: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getPressMemosAPI(yearMonth);
};

// モフトレ通信
// 全メモ保存・更新
export const putAllPressMemo = (memoArr: ReportConst.MemoForAPI[], yearMonth: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.putAllPressMemoAPI(memoArr, yearMonth);
};

// モフトレ通信メモ
export const getPressMemo = (userId: string, yearMonth: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getPressMemoAPI(userId, yearMonth);
};

// モフトレ通信
// メモ保存・更新
export const putPressMemo = (userId: string, memo: object, yearMonth: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.putPressMemoAPI(userId, memo, yearMonth);
};

// モフトレ通信
// メモ削除
export const deletePressMemo = (userId: string, yearMonth: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.deletePressMemoAPI(userId, yearMonth);
};

// モフらく
// 利用者基本情報一覧取得(複数)
export const getMoffRakuUsers = (): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuUsersAPI();
};
// 利用者基本情報更新
export const putMoffRakuUsers = (basicInfoArr: { data: MoffRakuConst.BasicInfoFormat[] }) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.putMoffRakuUsersAPI(basicInfoArr);
};
// 利用者基本情報削除
export const deleteMoffRakuUsers = (userId: string): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.deleteMoffRakuUsersAPI(userId);
};

export const getMoffRakuICD10 = (): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuICD10API();
};
export const getMoffRakuICFActivity = (): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuICFActivityAPI();
};
export const getMoffRakuICFFunction = (): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuICFFunctionAPI();
};
export const getMoffRakuICFParticipation = (): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuICFParticipationAPI();
};
export const getMoffRakuSupport = (): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuSupportAPI();
};
// モフらく moff_raku_users 用
export const getMoffRakuBasicInfoAndMasterData = (): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuBasicInfoAndMasterDataAPI();
};
// モフらく 利用者の計画書一覧 moff_raku/<user_id> 用
export const getMoffRakuUserPlansData = (userId: string): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuUserPlansDataAPI(userId);
};
// モフらく 利用者の計画書の入力/編集
export const getMoffRakuUserPlanData = (userId: string, planId?: string): any => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuUserPlanDataAPI(userId, planId);
};

// モフらく 利用者の計画書の新規作成&更新
export const postMoffRakuPlan = (userId: string, plan: MoffRakuConst.PlanFormat) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.postMoffRakuPlanDataAPI(userId, plan);
};
export const putMoffRakuPlan = (userId: string, planId: string, plan: MoffRakuConst.PlanFormat) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.putMoffRakuPlanDataAPI(userId, planId, plan);
};
// モフらく 利用者の計画書のExcelダウンロード
export const getMoffRakuPlanFile = (userId: string, planId: string, plan: MoffRakuConst.PlanFormat) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuPlanFileAPI(userId, planId, plan);
};
// モフらく 利用者の計画書の削除
export const deleteMoffRakuPlan = (userId: string, planId: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.deleteMoffRakuPlanDataAPI(userId, planId);
};

// モフらく LIFE CSV用
export const getFunctionalTrainingPlansFromTo = (uniqueIds: ResponseData[], month: string) => {
  return isMoffAPIError ? moffAPIErrorAction : moffAPIInstance.getMoffRakuPlansFromToFunc(uniqueIds, month);
};

/**
 * 初期化する.
 *
 * @returns
 */
export const initialize = () => {
  return {
    type: MoffAPIActionType.INITIALIZE,
    result: {},
  };
};

export const duplicateRepeatSort = () => {
  return {
    type: MoffAPIActionType.DUPLICATE_REPEAT_SORT,
  };
};

export const pressDuplicateRepeatSort = () => {
  return {
    type: MoffAPIActionType.PRESS_DUPLICATE_REPEAT_SORT,
  };
};

export const addDisplayDate = (payload: DisplayPayload) => ({
  type: MoffAPIActionType.ADD_DISPLAY_DATE,
  payload,
});

export const sortByKey = (payload: { key: keyof User; isDescendingOrder: boolean }) => {
  return {
    type: MoffAPIActionType.SORT_BY_KEY,
    payload,
  };
};
