// MoffAPI
import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import { MoffAPIActionType, LOCOMO_TYPE, ADL_TYPE } from '../constants/MoffAPI';
import { HdsrData } from '../constants/HDSR';
import { BarthelIndexData } from '../constants/BarthelIndexAPI';
import { createAxiosInstance } from '../constants/AWS';
import * as KirokuConst from '../constants/Kiroku';
import * as ReportConst from '../constants/Report';
import * as UserSettingConst from '../constants/UserSetting';
import { Training, TOGGLE_POSITION, TRAINING_TYPE } from '../constants/Training';
import * as MoffRakuConst from '../constants/MoffRaku';

import { BarthelIndexAPI } from './BarthelIndexAPI';
import { HDSRAPI } from './HDSRAPI';

import { getAppUserData } from './AppUser';
import { apiRequestFunc, errorFunc } from '../utils/apiUtil';
import { xlsxFileDownload, waitTimeMs, isEmpty } from '../utils/commonUtil';
import { getYearMonthArray, getFirstEndDay } from '../utils/dateUtil';
import { hdsrMonthsInTimeRange } from '../utils/LiterallyReportModule';
import { convertToAttachmentPosition } from '../utils/locomoAdlModule';
import { ResponseData } from '../constants/AppUser';
import { DATA_CHECK_INTERVAL } from '../constants/csvCreate';
import * as FunctionalTrainingPlan from '../constants/MoffRaku';

const API_VERSION = 'v2';

// apiUrlUpdateMonthlyの切り替えURL定義
// ロコモ予防トレーニング・詳細用
const LOCOMO_URL = `locomo`;

// 日常生活動作トレーニング・詳細用
const ADL_URL = `adl`;

export class MoffAPI {
  /** インスタンス */
  private static _instance: MoffAPI;
  private axiosInstance!: AxiosInstance;

  // .envの設定
  private API_BASE_URL = String(process.env.REACT_APP_MOFF_API_URL);

  /** インスタンスの取得 */
  public static get instance(): MoffAPI | null {
    // _inctanceが存在しない場合に、new MoffAPI()を実行する。
    if (!this._instance) {
      this._instance = new MoffAPI();
      // moffAPIはjwtTokenで認証を行うため、初回instance生成時はapiKey・jwtToken共にnullで設定
      // ユーザーセッション取得の際（ページロード時）に、jwtTokenを含むAxiosInstanceで書き換える
      // （jwtToken取得を非同期で行うためreduxのフローにのせる）
      const axiosInstance = createAxiosInstance(this._instance.API_BASE_URL, null, null);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  // jwtTokenを含むAxiosInstanceに書き換える関数
  public async setAxiosInstanceWithJwtToken(): Promise<void> {
    const jwtToken: string = await Auth.currentAuthenticatedUser()
      .then((user: any) => user.signInUserSession.idToken.jwtToken)
      .catch(() => '');
    this.axiosInstance = await createAxiosInstance(this.API_BASE_URL, null, jwtToken);
  }

  // 共通
  // 利用者一覧取得(1人) [APIリクエスト]
  public getUserAPI(userId: string) {
    const uniqueLogicFunc: any = async () => await this.getUserFunc(userId);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 利用者一覧作成(1人) [APIリクエスト]
  public createUserAPI(params: UserSettingConst.ParamsFormat) {
    const uniqueLogicFunc: any = async () => {
      const user = await this.createUserFunc(params);
      return _.zipObject(['user', 'is_created'], [user, true]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 利用者一覧更新(1人) [APIリクエスト]
  public updateUserAPI(userId: string, params: UserSettingConst.ParamsFormat) {
    const uniqueLogicFunc: any = async () => {
      const user = await this.updateUserFunc(userId, params);
      return _.zipObject(['user', 'is_updated'], [user, true]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 利用者一覧削除(1人) [APIリクエスト]
  public deleteUserAPI(userId: string) {
    const uniqueLogicFunc: any = async () => {
      const user = await this.deleteUserFunc(userId);
      return _.zipObject(['user', 'is_deleted'], [user, true]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 共通
  // 利用者一覧取得(複数) [APIリクエスト]
  public getUsersAPI() {
    const uniqueLogicFunc: any = async () => await this.getUsersFunc().then((response) => response.data.data);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 共通
  // 施設情報取得(1施設) [APIリクエスト]
  public getInstitutionAPI(institutionSub: string) {
    const uniqueLogicFunc: any = async () => await this.getInstitutionAPI(institutionSub);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // ロコモ・ADLトレーニング
  // 月間データ取得(typeは1 or 2, yearmonthは'yyyy-mm'形式) [APIリクエスト]
  public getMonthlyDataAPI(type: number, userId: string, yearmonth: string): any {
    const uniqueLogicFunc: any = async () =>
      await this.getMonthlyDataFunc(type, userId, yearmonth).then((response) => {
        return { monthlyData: response.data };
      });
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフトレトレーニング
  // トレーニングの詳細データを取得 [APIリクエスト]
  public getDetailDataAPI(userId: string, month: string, togglePosition: TOGGLE_POSITION) {
    const uniqueLogicFunc = async () => {
      const training = await this.getTrainingFunc().then((response) => response.data.data);
      const user = await this.getUserFunc(userId).then((response) => response.data);
      const [locomoMonthlyData, adlMonthlyData] = await Promise.all(
        _.map([TRAINING_TYPE.Locomo, TRAINING_TYPE.Adl], async (type) =>
          this.getMonthlyByAttachmentPositionFunc(type.id, userId, month, togglePosition).then(
            (response) => response.data,
          ),
        ),
      );
      return _.zipObject(
        ['training', 'user', 'locomoMonthlyData', 'adlMonthlyData'],
        [training, user, locomoMonthlyData, adlMonthlyData],
      );
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // ロコモ・ADLトレーニング
  // トレーニング前月比較データを取得 [APIリクエスト]
  public getCompareDataAPI(
    userId: string,
    startParams: { date: string; togglePosition: TOGGLE_POSITION },
    endParams: { date: string; togglePosition: TOGGLE_POSITION },
  ) {
    const uniqueLogicFunc = async () => {
      const [locomoStartData, locomoEndData, adlStartData, adlEndData] = await Promise.all(
        _.map(
          [
            [TRAINING_TYPE.Locomo, startParams],
            [TRAINING_TYPE.Locomo, endParams],
            [TRAINING_TYPE.Adl, startParams],
            [TRAINING_TYPE.Adl, endParams],
          ],
          async (params: [TRAINING_TYPE, { date: string; togglePosition: TOGGLE_POSITION }]) =>
            this.getMonthlyByAttachmentPositionFunc(
              params[0].id,
              userId,
              params[1].date,
              params[1].togglePosition,
            ).then((response) => response.data),
        ),
      );
      return _.zipObject(
        ['locomoStartData', 'locomoEndData', 'adlStartData', 'adlEndData'],
        [locomoStartData, locomoEndData, adlStartData, adlEndData],
      );
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // ロコモ・ADLトレーニング
  // トレーニング長期データを取得 [APIリクエスト]
  public getLongTermDataAPI(userId: string, params: string[], togglePosition: TOGGLE_POSITION) {
    const uniqueLogicFunc = async () => {
      const locomoLongTermData = await Promise.all(
        _.map(params, async (month: string) =>
          this.getMonthlyByAttachmentPositionFunc(TRAINING_TYPE.Locomo.id, userId, month, togglePosition).then(
            (response) => response.data,
          ),
        ),
      );
      const adlLongTermData = await Promise.all(
        _.map(params, async (month: string) =>
          this.getMonthlyByAttachmentPositionFunc(TRAINING_TYPE.Adl.id, userId, month, togglePosition).then(
            (response) => response.data,
          ),
        ),
      );
      return _.zipObject(['locomoLongTermData', 'adlLongTermData'], [locomoLongTermData, adlLongTermData]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // ロコモ・ADLトレーニング
  // 月間データサイズ取得(typeは1 or 2, yearmonthは'yyyy-mm'形式) [APIリクエスト]
  public getMonthlyDataSizeAPI(type: number, userId: string, yearmonth: string): any {
    const uniqueLogicFunc: any = async () => {
      this.getMonthlyDataFunc(type, userId, yearmonth).then((response) => {
        const size = _.size(response.data);
        return { yearmonth, size };
      });
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 個別機能訓練
  // トレーニングの記録用詳細データを取得 [APIリクエスト]
  public getTrainingDetailDataAPI(params: any[]): any {
    const uniqueLogicFunc: any = async () => {
      const training = await this.getTrainingFunc().then((response) => response.data.data);
      const user = await this.getUserFunc(params[1]).then((response) => response.data);
      const filteredTraining = await this.getFunctionalTrainingHistoryFunc(
        params[2][0],
        params[2][1],
        params[2][2],
      ).then((response) => response.data.data);
      return _.zipObject(['training', 'user', 'filteredTraining'], [training, user, filteredTraining]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 個別機能訓練
  // トレーニング履歴新規 [APIリクエスト]
  public postFunctionalTrainingHistoryAPI(userId: string, params: KirokuConst.KirokuRequestParamsFormat) {
    const uniqueLogicFunc: any = async () => {
      return this.postFunctionalTrainingHistoryFunc(userId, params).then((response) => response.data.training);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 個別機能訓練
  // トレーニング修正 [APIリクエスト]
  public putFunctionalTrainingHistoryAPI(userId: string, params: KirokuConst.KirokuRequestParamsFormat) {
    const uniqueLogicFunc: any = async () => {
      return this.putFunctionalTrainingHistoryFunc(userId, params, params.datetime).then(
        (response) => response.data.training,
      );
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 個別機能訓練
  // トレーニング履歴新規と更新を同時に [APIリクエスト]
  public postAndPutFunctionalTrainingHistoryAPI(
    userId: string,
    convertedUpdateRecords: ReadonlyArray<KirokuConst.KirokuRequestParamsFormat[]>,
  ) {
    const uniqueLogicFunc: any = async () => {
      const postAndPutResult = await Promise.all(
        _.map(convertedUpdateRecords, async (convertedUpdateRecord: KirokuConst.KirokuRequestParamsFormat) => {
          try {
            // リクエストデータの「new」が true か false かで「新規」・「更新」を判断。データ型は「新規」・「更新」共に同じ。
            if (convertedUpdateRecord.new) {
              delete convertedUpdateRecord.new;
              return this.postFunctionalTrainingHistoryFunc(userId, convertedUpdateRecord).then(
                (response) => response.data.training,
              );
            } else {
              delete convertedUpdateRecord.new;
              // APIリクエストを送る際に、更新対象レコード検索文字列「datetime」、
              // 更新内容「putParams」を分割してエンドポイントに付加する必要あり
              const { datetime, ...putParams } = convertedUpdateRecord;
              return this.putFunctionalTrainingHistoryFunc(userId, putParams, datetime).then(
                (response) => response.data.training,
              );
            }
          } catch {
            alert('データ保存に失敗しました');
          }
        }),
      );
      alert('データを保存しました');
      return postAndPutResult.length === 0 ? { longTermData: [] } : { longTermData: postAndPutResult };
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 個別機能訓練
  // トレーニング履歴・削除 [APIリクエスト]
  public deleteFunctionalTrainingHistoryAPI(userId: string, datetimeArr: string[]) {
    const uniqueLogicFunc: any = async () => {
      try {
        await Promise.all(
          _.map(datetimeArr, (datetime: string[]) =>
            this.axiosInstance.delete(`${API_VERSION}/users/${userId}/functional_training_logs/${datetime}`),
          ),
        );
        alert('データの削除が完了しました。');
      } catch {
        alert('データの削除に失敗しました。');
      }
      return {};
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 個別機能訓練
  // 一括入力の [APIリクエスト]
  public postFunctionalTrainingAllUserAPI(user: any, records: any[]): any {
    const uniqueLogicFunc: any = async () => {
      return Promise.all(
        _.map(records, async (record: any) => {
          await this.postFunctionalTrainingHistoryFunc(user.user_id, record).then((response) => {
            return response.data.training;
          });
        }),
      );
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフトレ通信
  // 指定月から12ヶ月遡り、トレーニング結果が存在する月のデータを取得 [APIリクエスト]
  public getMoffTrainingDataAPI(userId: string, yearMonthArr: string[], intervalTimeMs = 500): any {
    const uniqueLogicFunc: any = async () => {
      const user = await this.getUserFunc(userId).then((response) => {
        return response.data;
      });

      // '2017-07'のYYYY-MMがキー, 中身は個数(LocomoとADLで片方さえ入れば良い).
      const dispYearMonth: string[] = [];
      await Promise.all(
        _.map(yearMonthArr, async (yearMonth: string) => {
          // 連続してAPIリクエストを行うため時間間隔を開ける
          await waitTimeMs(intervalTimeMs);
          await Promise.all(
            _.map([LOCOMO_TYPE, ADL_TYPE], async (type: number) => {
              return this.getMonthlyDataFunc(type, userId, yearMonth).then((response) => {
                // 結果は複数あるので追加する.
                const size = _.size(response.data);
                if (size > 0) {
                  dispYearMonth.push(yearMonth);
                }
              });
            }),
          );
          const from_date = moment(yearMonth.replace('-', '')).startOf('month').format('YYYY-MM-DD');
          const to_date = moment(yearMonth.replace('-', '')).endOf('month').format('YYYY-MM-DD');
          await this.getFunctionalTrainingHistoryFunc(userId, from_date, to_date)
            .then((response) => {
              const size = _.size(response.data.data);
              if (size > 0) {
                dispYearMonth.push(yearMonth);
              }
            })
            .catch((error) => {
              throw error;
            });
        }),
      );
      const filteredDispYearMonth = Array.from(new Set(dispYearMonth)).sort().reverse();
      return _.zipObject(['dispYearMonth', 'user'], [filteredDispYearMonth, user]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフトレ通信
  // モフトレ通信一括印刷のためロコモ・ADLトレーニングの全ユーザー分データを取得 [APIリクエスト]
  public getMoffUsersDetailDataAPI(users: any[], yearMonth: string, intervalTimeMs: number): any {
    const uniqueLogicFunc: any = async () => {
      // モフトレ ロコモ・ADL、ユーザー毎のトレーニングデータの取得
      return Promise.all(
        _.map(users, async (user: any) => {
          const trainingResult = _.zipObject(
            ['locomo', 'adl'],
            await Promise.all(
              _.map([LOCOMO_TYPE, ADL_TYPE], async (type: number) => {
                return this.getMonthlyDataFunc(type, user.user_id, yearMonth).then((response) => response.data);
              }),
            ),
          );
          const hasDataThisMonth = this.hasDateThisMonthPress(yearMonth, trainingResult);
          // 指定時間の待ちを入れる
          await waitTimeMs(intervalTimeMs);
          if (hasDataThisMonth) {
            return { ...user, ...trainingResult };
          }
        }),
      );
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフトレ通信
  // 全ユーザーとメモを取得
  public getUsersAndPressMemosAPI(yearMonth: string) {
    const uniqueLogicFunc: any = async () => {
      const users = await this.getUsersFunc().then((response) => response.data.data);
      const memos = await this.getPressMemosFunc(yearMonth).then((response) => response.data.data);
      return _.zipObject(['memos', 'users'], [memos, users]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフトレ通信
  // 全メモ保存・更新
  public putAllPressMemoAPI(memoArr: ReportConst.MemoForAPI[], yearMonth: string) {
    const uniqueLogicFunc: any = async () => {
      return Promise.all(
        _.map(memoArr, async (memoData) => {
          return await this.putPressMemoFunc(memoData.user_id, memoData.memo, yearMonth);
        }),
      );
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフトレ通信
  // メモ取得
  public getPressMemoAPI(userId: string, yearMonth: string): any {
    const uniqueLogicFunc: any = async () => {
      const memo = await this.getPressMemoFunc(userId, yearMonth)
        .then((response) => response.data)
        .catch((error: any) => {
          // memoが存在しない時はnullを返す
          if (error.response.status === 404) {
            return null;
          }
          // それ以外は普通のエラーを返す
          throw error;
        });
      return {
        memo: memo,
      };
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフトレ通信
  // メモ保存・更新
  public putPressMemoAPI(userId: string, comment: object, yearMonth: string) {
    const uniqueLogicFunc: any = async () => {
      return await this.putPressMemoFunc(userId, comment, yearMonth);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフトレ通信
  // メモ削除
  public deletePressMemoAPI(userId: string, yearMonth: string) {
    const uniqueLogicFunc: any = async () => {
      return this.deletePressMemoFunc(userId, yearMonth).then((response) => response.data);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフトレチェック
  // モフトレチェックユーザー情報・施設情報を取得 [APIリクエスト]
  public getMoffCheckDetailDataAPI(userId: string) {
    const uniqueLogicFunc: any = async () => {
      const user = await this.getUserFunc(userId).then((response) => response.data);
      const institutions = await this.getInstitutionsFunc().then((response) => response.data);
      return _.zipObject(['user', 'institutions'], [user, institutions]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // 機能訓練レポート
  // ユーザー情報・施設・トレーニング情報を取得 [APIリクエスト]
  public getLiterallyReportDataAPI(userId: string, selectedDate: moment.Moment, intervalTimeMs = 1000) {
    const BI = BarthelIndexAPI.instance;
    if (BI === null) {
      return errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.');
    }
    const HDSR = HDSRAPI.instance;
    if (HDSR === null) {
      return errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc: () => void = async () => {
      const user = await this.getUserFunc(userId).then((response) => response.data);
      const appUser = await getAppUserData(userId, MoffAPIActionType.ERROR);
      const institutions = await this.getInstitutionsFunc().then((response) => response.data);

      const resultYearMonthObj: { [key: string]: number } = {};
      // 12ヶ月 + 1ヶ月（最初の月の1つ前）分.
      const yearMonthArr: string[] = getYearMonthArray(selectedDate.format('YYYY'), selectedDate.format('MM'), 12 + 1);
      // '2017-07'のYYYY-MMがキー, 中身は個数(LocomoとADLで片方さえ入れば良い).
      const resultTrainingObj = _.zipObject(
        ['locomo', 'adl'],
        await Promise.all(
          _.map([LOCOMO_TYPE, ADL_TYPE], async (type: number) => {
            const trainingHistory = await Promise.all(
              _.map(yearMonthArr, async (yearMonth: string) => {
                // 連続してAPIリクエストを行うため時間間隔を開ける
                await waitTimeMs(intervalTimeMs);
                return this.getMonthlyDataFunc(type, userId, yearMonth).then(
                  (response: AxiosResponse<{ [key: string]: { [key: string]: Training } }>) => {
                    // 結果は複数あるので追加する.
                    const size = _.size(response.data);
                    if (size > 0) {
                      resultYearMonthObj[yearMonth] = size;
                    }
                    return { ...response.data };
                  },
                );
              }),
            );
            return _.defaults({}, ...trainingHistory);
          }),
        ),
      );
      let unionYearMonthArr = Object.keys(resultYearMonthObj);
      if (appUser !== null) {
        // 選んだ月の1年前の1日と、選んだ日の最終日.
        const [startDate, endDate] = getFirstEndDay(selectedDate.format('YYYY'), selectedDate.format('MM'), 12 + 1);
        const bi = await BI.getBiFunc(appUser.data.unique_id, startDate, endDate)
          .then((response: AxiosResponse<BarthelIndexData[]>) => {
            return response.data;
          })
          .catch((error: AxiosError) => {
            // BIRecordが存在しないユーザーはnullを返す
            if (error.response?.status === 404) {
              return null;
            }
            // それ以外は普通のエラーを返す
            throw error;
          });
        const hdsr = await HDSR.getHdsrAllRecordsFunc(appUser.data.unique_id)
          .then((response: AxiosResponse<{ total: number; data: HdsrData[] }>) => {
            return response.data;
          })
          .catch((error: AxiosError) => {
            // HDSRが存在しないユーザーはnullを返す
            if (error.response?.status === 404) {
              return null;
            }
            // それ以外は普通のエラーを返す
            throw error;
          });
        // BIは1年以内指定でデータを取得しているため、単純に月表示の形式にして返す
        const biMonths = bi
          ? _.map(bi, (biData) => {
              return moment(biData.record_time).format('YYYY-MM');
            })
          : [];
        // HDSRのデータが1年以内にある場合、その月の配列を返す
        const hdsrMonths = hdsr && hdsr.total > 0 ? hdsrMonthsInTimeRange(hdsr.data, startDate, endDate) : [];
        // ロコモ・ADL・BI・HDSRのデータが存在する月の配列を重複なく結合する
        unionYearMonthArr = _.union(biMonths, hdsrMonths, [...unionYearMonthArr]);
      }
      const dispYearMonth = _.map(unionYearMonthArr.sort().reverse(), (key) => {
        return { [`${key}`]: resultYearMonthObj[key] ?? 0 };
      });
      return _.zipObject(
        ['user', 'institutions', 'dispYearMonth', 'resultTrainingObj'],
        [user, institutions, _.defaults({}, ...dispYearMonth), resultTrainingObj],
      );
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフらく
  // 基本情報の取得
  public getMoffRakuUsersAPI() {
    const uniqueLogicFunc: any = async () => await this.getMoffRakuUsersFunc().then((response) => response.data.data);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }
  public putMoffRakuUsersAPI(updateRecords: ReadonlyArray<MoffRakuConst.BasicInfoFormat[]>) {
    const uniqueLogicFunc: any = async () =>
      await this.putMoffRakuUsersFunc(updateRecords).then((response) => response.data.data);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }
  public deleteMoffRakuUsersAPI(userId: string) {
    const uniqueLogicFunc: any = async () =>
      await this.deleteMoffRakuUsersFunc(userId).then((response) => response.data.data);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  public getMoffRakuICD10API() {
    const uniqueLogicFunc: any = async () => await this.getMoffRakuICD10Func().then((response) => response.data.data);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }
  public getMoffRakuICFActivityAPI() {
    const uniqueLogicFunc: any = async () =>
      await this.getMoffRakuICFActivityFunc().then((response) => response.data.data);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }
  public getMoffRakuICFFunctionAPI() {
    const uniqueLogicFunc: any = async () =>
      await this.getMoffRakuICFFunctionFunc().then((response) => response.data.data);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }
  public getMoffRakuICFParticipationAPI() {
    const uniqueLogicFunc: any = async () =>
      await this.getMoffRakuICFParticipationFunc().then((response) => response.data.data);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }
  public getMoffRakuSupportAPI() {
    const uniqueLogicFunc: any = async () => await this.getMoffRakuSupportFunc().then((response) => response.data.data);
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }
  // モフらく 基本情報とマスターデータ
  // 全ユーザーとメモを取得 (url: moff_raku_users での取得用)
  public getMoffRakuBasicInfoAndMasterDataAPI() {
    const uniqueLogicFunc: any = async () => {
      const users = await this.getUsersFunc().then((response) => response.data.data);
      const usersBasicInfo = await this.getMoffRakuUsersFunc().then((response) => response.data.data);
      const ls_icd10 = localStorage.getItem('icd10');
      const cache_icd10 = ls_icd10 ? JSON.parse(ls_icd10) : null;
      const icd10 =
        cache_icd10 && cache_icd10.length > 1
          ? cache_icd10
          : await this.getMoffRakuICD10Func().then((response) => response.data.data);
      if (!cache_icd10 || (cache_icd10 && cache_icd10.length === 0))
        localStorage.setItem('icd10', JSON.stringify(icd10));

      return _.zipObject(['users', 'usersBasicInfo', 'icd10'], [users, usersBasicInfo, icd10]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフらく 利用者の計画書一覧
  public getMoffRakuUserPlansDataAPI(userId: string) {
    const uniqueLogicFunc: any = async () => {
      const user = await this.getUserFunc(userId).then((response) => response.data);
      const plans = await this.getMoffRakuPlansFunc(userId).then((response) => response.data.data);
      return _.zipObject(['user', 'plans'], [user, plans]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフらく 計画書入力/編集
  public getMoffRakuUserPlanDataAPI(userId: string, planId?: string) {
    const uniqueLogicFunc: any = async () => {
      const user = await this.getUserFunc(userId).then((response) => response.data);
      const institution = await this.getInstitutionsFunc().then((response) => response.data);
      const usersBasicInfo = await this.getMoffRakuUsersFunc().then((response) => response.data.data);
      const plan = planId ? await this.getMoffRakuPlanFunc(userId, planId).then((response) => response.data) : null;
      // MASTERデータ系はキャッシュ化
      // TODO API側の実装がまだないので、更新がありそうな場合はバックエンドAPIも追加する
      // const isMoffRakuMasterUpdated = await this.getMoffRakuMasterUpdatedFunc().then((response) => response.data)
      // if (isMoffRakuMasterUpdated){
      //   localStorage.removeItem('bundle');
      // }
      const ls_bundle = localStorage.getItem('bundle');
      const ls_icd10 = localStorage.getItem('icd10');
      const ls_icfactivity = localStorage.getItem('icfactivity');
      const ls_icffunction = localStorage.getItem('icffunction');
      const ls_icfparticipation = localStorage.getItem('icfparticipation');
      const ls_support = localStorage.getItem('support');
      const cache_bundle = ls_bundle ? JSON.parse(ls_bundle) : null;
      const cache_icd10 = ls_icd10 ? JSON.parse(ls_icd10) : null;
      const cache_icfactivity = ls_icfactivity ? JSON.parse(ls_icfactivity) : null;
      const cache_icffunction = ls_icffunction ? JSON.parse(ls_icffunction) : null;
      const cache_icfparticipation = ls_icfparticipation ? JSON.parse(ls_icfparticipation) : null;
      const cache_support = ls_support ? JSON.parse(ls_support) : null;

      // bundleのみ、キャッシュは1週間ごとに更新する
      let bundle;
      const currentDate = new Date(); // 現在の日時を取得
      const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      if (
        !cache_bundle ||
        (cache_bundle && !cache_bundle.updated_at) ||
        (cache_bundle.updated_at && new Date(cache_bundle.updated_at) < oneWeekAgo)
      ) {
        const db_bundle = await this.getMoffRakuBundleFunc().then((response) => response.data);
        const updatedBundle = {
          ...db_bundle,
          updated_at: currentDate.toISOString(),
        };
        bundle = updatedBundle;
        localStorage.setItem('bundle', JSON.stringify(bundle));
      } else {
        bundle = cache_bundle;
      }

      const icd10 =
        cache_icd10 && cache_icd10.length > 0
          ? cache_icd10
          : await this.getMoffRakuICD10Func().then((response) => response.data.data);
      const icfactivity =
        cache_icfactivity && cache_icfactivity.length > 0
          ? cache_icfactivity
          : await this.getMoffRakuICFActivityFunc().then((response) => response.data.data);
      const icffunction =
        cache_icffunction && cache_icffunction.length > 0
          ? cache_icffunction
          : await this.getMoffRakuICFFunctionFunc().then((response) => response.data.data);
      const icfparticipation =
        cache_icfparticipation && cache_icfparticipation.length > 0
          ? cache_icfparticipation
          : await this.getMoffRakuICFParticipationFunc().then((response) => response.data.data);
      const support =
        cache_support && cache_support.length > 0
          ? cache_support
          : await this.getMoffRakuSupportFunc().then((response) => response.data.data);

      localStorage.setItem('icd10', JSON.stringify(icd10));
      localStorage.setItem('icfactivity', JSON.stringify(icfactivity));
      localStorage.setItem('icffunction', JSON.stringify(icffunction));
      localStorage.setItem('icfparticipation', JSON.stringify(icfparticipation));
      localStorage.setItem('support', JSON.stringify(support));

      return _.zipObject(
        [
          'user',
          'institution',
          'usersBasicInfo',
          'plan',
          'bundle',
          'icd10',
          'icfactivity',
          'icffunction',
          'icfparticipation',
          'support',
        ],
        [user, institution, usersBasicInfo, plan, bundle, icd10, icfactivity, icffunction, icfparticipation, support],
      );
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフらく計画書 新規と更新を同時に [APIリクエスト]
  public postMoffRakuPlanDataAPI(userId: string, updateRecord: MoffRakuConst.PlanFormat) {
    const uniqueLogicFunc: any = async () => {
      const plan = await this.postMoffRakuPlanFunc(userId, updateRecord);
      return _.zipObject(['plan', 'is_created'], [plan, true]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフらく 計画書 更新 [APIリクエスト]
  public putMoffRakuPlanDataAPI(userId: string, planId: string, updateRecord: MoffRakuConst.PlanFormat) {
    const uniqueLogicFunc: any = async () => {
      const plan = await this.putMoffRakuPlanFunc(userId, planId, updateRecord);
      return _.zipObject(['plan', 'is_updated'], [plan, true]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }
  // モフらく 計画書 ファイルDL
  public getMoffRakuPlanFileAPI(userId: string, planId: string, updateRecord: MoffRakuConst.PlanFormat) {
    const uniqueLogicFunc: any = async () => {
      // 保存してから出力
      await this.putMoffRakuPlanFunc(userId, planId, updateRecord);
      const response = await this.getMoffRakuPlanFileFunc(userId, planId);
      xlsxFileDownload(response.data, planId);
      return _.zipObject(['is_created', 'is_updated'], [false, false]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  // モフらく 計画書 削除 [APIリクエスト]
  public deleteMoffRakuPlanDataAPI(userId: string, planId: string) {
    const uniqueLogicFunc: any = async () => {
      // await this.deleteMoffRakuPlanFunc(userId, planId).then((response) => response.data.data);
      const res = await this.deleteMoffRakuPlanFunc(userId, planId);
      return _.zipObject(['res', 'is_deleted'], [res, true]);
    };
    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  //----------------------------------------------

  // 利用者一覧取得関数(1人) [APIリクエストから呼び出し]
  public async getUserFunc(userId: string) {
    return this.axiosInstance.get(`${API_VERSION}/users/${userId}`);
  }

  // 利用者作成関数(1人) [APIリクエストから呼び出し]
  public async createUserFunc(params: UserSettingConst.ParamsFormat) {
    return this.axiosInstance.post(`${API_VERSION}/users`, params);
  }

  // 利用者更新関数(1人) [APIリクエストから呼び出し]
  public async updateUserFunc(userId: string, params: UserSettingConst.ParamsFormat) {
    return this.axiosInstance.put(`${API_VERSION}/users/${userId}`, params);
  }

  // 利用者削除関数(1人) [APIリクエストから呼び出し]
  public async deleteUserFunc(userId: string) {
    return this.axiosInstance.delete(`${API_VERSION}/users/${userId}`);
  }

  // 利用者一覧取得関数(複数) [APIリクエストから呼び出し]
  public async getUsersFunc() {
    return this.axiosInstance.get(`${API_VERSION}/users`);
  }

  // 施設情報取得関数(単数) [APIリクエストから呼び出し]
  public async getInstitutionsFunc() {
    return this.axiosInstance.get(`${API_VERSION}/institutions/get`);
  }

  // トレーニング取得関数 [APIリクエストから呼び出し]
  public async getTrainingFunc() {
    return this.axiosInstance.get(`${API_VERSION}/trainings`);
  }

  // 月間データ取得関数 [APIリクエストから呼び出し]
  public async getMonthlyDataFunc(type: number, userId: string, yearmonth: string) {
    const moffCategoryURL = type === LOCOMO_TYPE ? LOCOMO_URL : ADL_URL;
    return this.axiosInstance.get(
      `${API_VERSION}/users/${userId}/training_logs/${moffCategoryURL}/${yearmonth}/summaries`,
    );
  }

  // 月間データ取得関数 [APIリクエストから呼び出し]
  public async getMonthlyByAttachmentPositionFunc(
    type: number,
    userId: string,
    yearmonth: string,
    togglePosition: TOGGLE_POSITION,
  ) {
    const moffCategoryURL = type === LOCOMO_TYPE ? LOCOMO_URL : ADL_URL;
    const attachmentPositon = convertToAttachmentPosition(togglePosition);
    const option = attachmentPositon?.option ? { params: { dominant: attachmentPositon.option } } : undefined;
    return this.axiosInstance.get(
      `${API_VERSION}/users/${userId}/training_logs/${moffCategoryURL}/${yearmonth}/summaries`,
      option,
    );
  }

  // 記録用トレーニング履歴・取得関数 [APIリクエストから呼び出し]
  // startDate, endDateを'2018-01', '2018-02'の月までの形式ではなく
  // startDate, endDateを'2018-01-26', '2018-02-02'のように日も忘れずに指定する事。
  public async getFunctionalTrainingHistoryFunc(userId: string, startDate: string, endDate: string) {
    return this.axiosInstance.get(
      `${API_VERSION}/users/${userId}/functional_training_logs/date/${startDate}/${endDate}`,
    );
  }

  // 記録用トレーニング履歴・新規関数 [APIリクエストから呼び出し]
  public async postFunctionalTrainingHistoryFunc(userId: string, params: KirokuConst.KirokuRequestParamsFormat) {
    return this.axiosInstance.post(`${API_VERSION}/users/${userId}/functional_training_logs`, params);
  }

  // 記録用トレーニング履歴・修正関数 [APIリクエストから呼び出し]
  public async putFunctionalTrainingHistoryFunc(
    userId: string,
    params: Omit<KirokuConst.KirokuRequestParamsFormat, 'datetime'>,
    timeTexConverted: string,
  ) {
    return this.axiosInstance.put(
      `${API_VERSION}/users/${userId}/functional_training_logs/${timeTexConverted}`,
      params,
    );
  }

  // モフトレ通信メモ・取得関数（複数）[APIリクエストから呼び出し]
  public async getPressMemosFunc(yearMonth: string) {
    return this.axiosInstance.get(`${API_VERSION}/training_comments/${yearMonth}`);
  }

  // モフトレ通信メモ・取得関数（単数）[APIリクエストから呼び出し]
  public async getPressMemoFunc(userId: string, yearMonth: string) {
    return this.axiosInstance.get(`${API_VERSION}/users/${userId}/training_comments/${yearMonth}`);
  }

  // モフトレ通信メモ・新規/修正関数 [APIリクエストから呼び出し]
  public async putPressMemoFunc(userId: string, memo: object, yearMonth: string) {
    return this.axiosInstance.put(`${API_VERSION}/users/${userId}/training_comments/${yearMonth}`, memo);
  }

  // モフトレ通信メモ・削除関数 [APIリクエストから呼び出し]
  public async deletePressMemoFunc(userId: string, yearMonth: string) {
    return this.axiosInstance.delete(`${API_VERSION}/users/${userId}/training_comments/${yearMonth}`);
  }

  /**
   * モフトレ通信一括PDF
   * locomo, adlのデータが１つでも指定月(１か月)にあれば return true
   */
  public hasDateThisMonthPress(yearMonth: string, trainingResult: any): boolean {
    let hasDataThisMonth = false;
    const startDate = moment(`${yearMonth}-1`.replace(/-/g, '/')).format('YYYY-MM-DD');
    const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

    if (!_.isEmpty(trainingResult.adl)) {
      Object.keys(trainingResult.adl).map((date: any) => {
        if (moment(date).isBetween(startDate, endDate, undefined, '[]')) {
          hasDataThisMonth = true;
        }
        // 一旦空で返す
        return null;
      });
    }

    if (!hasDataThisMonth && !_.isEmpty(trainingResult.locomo)) {
      Object.keys(trainingResult.locomo).map((date: any) => {
        if (moment(date).isBetween(startDate, endDate, undefined, '[]')) {
          hasDataThisMonth = true;
        }
        // 一旦空で返す
        return null;
      });
    }
    return hasDataThisMonth;
  }

  // モフらく
  public async getMoffRakuUsersFunc() {
    return this.axiosInstance.get(`${API_VERSION}/moff_raku/users`);
  }
  public async putMoffRakuUsersFunc(params: ReadonlyArray<MoffRakuConst.BasicInfoFormat[]>) {
    return this.axiosInstance.put(`${API_VERSION}/moff_raku/users`, params);
  }
  public async deleteMoffRakuUsersFunc(userId: string) {
    return this.axiosInstance.delete(`${API_VERSION}/moff_raku/users/${userId}`);
  }
  // GET: /v2/moff_raku/plans/{user_id}
  public async getMoffRakuPlansFunc(userId: string) {
    return this.axiosInstance.get(`${API_VERSION}/moff_raku/plans/${userId}`);
  }
  // GET: /v2/moff_raku/plans/{user_id}/plan/{plan_id}
  public async getMoffRakuPlanFunc(userId: string, planId: string) {
    return this.axiosInstance.get(`${API_VERSION}/moff_raku/plans/${userId}/plan/${planId}`);
  }
  // POST: /v2/moff_raku/plans/{user_id}
  public async postMoffRakuPlanFunc(userId: string, params: MoffRakuConst.PlanFormat) {
    return this.axiosInstance.post(`${API_VERSION}/moff_raku/plans/${userId}`, params);
  }
  // PUT: /v2/moff_raku/plans/{user_id}/plan/{plan_id}
  public async putMoffRakuPlanFunc(userId: string, planId: string, params: MoffRakuConst.PlanFormat) {
    return this.axiosInstance.put(`${API_VERSION}/moff_raku/plans/${userId}/plan/${planId}`, params);
  }

  // DELETE: /v2/moff_raku/plans/{user_id}/plan/{plan_id}
  public async deleteMoffRakuPlanFunc(userId: string, planId: string) {
    return this.axiosInstance.delete(`${API_VERSION}/moff_raku/plans/${userId}/plan/${planId}`);
  }

  // GET: /v2/moff_raku/plans/{user_id}/plan/{plan_id}/file
  public async getMoffRakuPlanFileFunc(userId: string, planId: string) {
    return this.axiosInstance.get(`${API_VERSION}/moff_raku/plans/${userId}/plan/${planId}/file`);
  }

  // GET: /v2/moff_raku/master/bundle
  public async getMoffRakuBundleFunc() {
    return this.axiosInstance.get(`${API_VERSION}/moff_raku/master/bundle`, { useCache: true });
  }

  // GET: /v2/moff_raku/master/icd10
  public async getMoffRakuICD10Func() {
    return this.axiosInstance.get(`${API_VERSION}/moff_raku/master/icd10`, { useCache: true });
  }
  // GET: /v2/moff_raku/master/icfactivity
  public async getMoffRakuICFActivityFunc() {
    return this.axiosInstance.get(`${API_VERSION}/moff_raku/master/icfactivity`, { useCache: true });
  }
  // GET: /v2/moff_raku/master/icffunction
  public async getMoffRakuICFFunctionFunc() {
    return this.axiosInstance.get(`${API_VERSION}/moff_raku/master/icffunction`, { useCache: true });
  }
  // GET: /v2/moff_raku/master/icfparticipation
  public async getMoffRakuICFParticipationFunc() {
    return this.axiosInstance.get(`${API_VERSION}/moff_raku/master/icfparticipation`, { useCache: true });
  }
  // GET: /v2/moff_raku/master/support
  public async getMoffRakuSupportFunc() {
    return this.axiosInstance.get(`${API_VERSION}/moff_raku/master/support`, { useCache: true });
  }

  // モフらく LIFE CSV: GET /v2/moff_raku/plans/{user_id}/from/{from}/to/{to}
  public getMoffRakuPlansFromToFunc(uniqueIds: ResponseData[], month: string) {
    const uniqueLogicFunc = async () => {
      const fromDate = moment(month).startOf('month').format('YYYY-MM-DD');
      const toDate = moment(month).endOf('month').format('YYYY-MM-DD');
      const data = await Promise.all(
        uniqueIds.map(async (uniqueId: ResponseData, i) => {
          await waitTimeMs(i * DATA_CHECK_INTERVAL);
          const url = `${API_VERSION}/moff_raku/plans/${uniqueId.app_user_id}/from/${fromDate}/to/${toDate}`;
          const data = await this.axiosInstance.get(url).then((response) => response.data);
          return {
            userId: uniqueId.app_user_id,
            data,
          };
        }),
      );
      const monthUsersRecords = data.filter((v) => !isEmpty(v.data.data));
      return {
        monthUsersRecords,
      };
    };

    return apiRequestFunc(
      FunctionalTrainingPlan.LOADING,
      FunctionalTrainingPlan.SUCCESS,
      FunctionalTrainingPlan.ERROR,
      uniqueLogicFunc,
    );
  }
}

export const createMoffAPIInstance: any = () => {
  // Moffインスタンスが存在しない場合、エラーアクションを返す
  return MoffAPI.instance === null
    ? [true, null, errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.')]
    : [false, MoffAPI.instance, null];
};
