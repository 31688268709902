// モフトレチェックAPI.
import { AxiosInstance, AxiosResponse } from 'axios';
import _ from 'lodash';

import { createAxiosInstance } from '../constants/AWS';
import { MoffAPI } from './MoffAPI';
import { getAppUserData } from './AppUser';
import {
  START_LOAD,
  SUCCESS,
  ERROR,
  FINISH,
  MOFF_CHECK_API_PREFIX_LIST,
  UnionMoffCheckTrainingType,
  ROMTable,
} from '../constants/MoffCheck';
import { waitTimeMs } from '../utils/commonUtil';
import { errorFunc, apiRequestFunc } from '../utils/apiUtil';
import * as MoffCheckModule from '../utils/MoffCheckModule';

/**
 * MoffCheckクラス.
 *
 * @export
 * @class MoffCheck
 */
export class MoffCheck {
  /** インスタンス */
  private static _instance: MoffCheck;
  private axiosInstance!: AxiosInstance;

  /** インスタンスの取得 */
  public static get instance(): MoffCheck | null {
    // _inctanceが存在しない場合に、new MoffAPI()を実行する。
    if (!this._instance) {
      this._instance = new MoffCheck();

      // .envの設定
      const API_BASE_URL = String(process.env.REACT_APP_MOFF_CHECK_API_URL);
      const API_KEY = String(process.env.REACT_APP_MOFF_CHECK_API_KEY);
      if (API_BASE_URL === 'undefined') {
        console.log('.envにREACT_APP_MOFF_CHECK_API_URLの設定がありません.');
        return null;
      }
      if (API_KEY === 'undefined') {
        console.log('.envにREACT_APP_MOFF_CHECK_API_KEYの設定がありません.');
        return null;
      }
      const axiosInstance = createAxiosInstance(API_BASE_URL, API_KEY);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  /**
   * モフトレチェックの全ての計測詳細データを取得する、APIリクエスト
   *
   * @param {string} userId
   * @param {string} startDate
   * @param {string} endDate
   * @returns
   */
  public getMoffCheckAllDataAPI(userId: string, startDate: string, endDate: string, isGetOldestDate = false): any {
    const moff = MoffAPI.instance;
    if (moff === null) {
      return errorFunc(ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc: any = async () => {
      // ユーザー情報の取得
      const userDataMoff: any = await moff.getUserFunc(userId).then((response: any) => {
        return response.data;
      });
      // ユーザーユニークIDの取得
      const dataAppUser: any = await getAppUserData(userId, ERROR);
      // モフトレチェック、トレーニングデータの取得
      const apiResponseData = _.map(
        await this.getAllMoffCheckData(dataAppUser.data.unique_id, startDate, endDate),
        (el) => el.data,
      );
      let oldestDate: any;
      if (isGetOldestDate) {
        oldestDate = await this.getAllMoffCheckOldestData(dataAppUser.data.unique_id);
      } else {
        oldestDate = '';
      }
      // 取得したデータを全てまとめる
      return _.zipObject(
        ['user', 'tug', 'balance', 'cs30', 'ss5', 'rom', 'grip_right', 'grip_left', 'height', 'weight', 'oldestdate'],
        [userDataMoff, ...apiResponseData, oldestDate],
      );
    };

    return apiRequestFunc(START_LOAD, SUCCESS, ERROR, uniqueLogicFunc);
  }

  /**
   * モフトレチェックの詳細データを取得する、APIリクエスト
   *
   * @param {string} userId
   * @param {string[][]} startAndEndDateList
   * @returns
   */
  public getFreeChoiceMonthMoffCheckAllDataAPI(userId: string, startAndEndDateList: string[][]) {
    const moff = MoffAPI.instance;
    if (moff === null) {
      return errorFunc(ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc = async () => {
      let apiResponseData: any[] = [];
      // ユーザー情報の取得
      const userDataMoff = await moff.getUserFunc(userId).then((response: any) => response.data);
      // ユーザーユニークIDの取得
      const dataAppUser = await getAppUserData(userId, ERROR);

      apiResponseData = await Promise.all(
        _.map(startAndEndDateList, async (startAndEndDate: string) => {
          // モフトレチェック、トレーニングデータの取得
          return _.map(
            await this.getAllMoffCheckData(dataAppUser.data.unique_id, startAndEndDate[0], startAndEndDate[1]),
            (el) => el.data,
          );
        }),
      );
      const convertApiResponsedata = MoffCheckModule.convertApiResponsedata(apiResponseData);
      // 取得したデータを全てまとめる
      return _.zipObject(
        ['user', 'tug', 'balance', 'cs30', 'ss5', 'rom', 'grip_right', 'grip_left'],
        [userDataMoff, ...convertApiResponsedata],
      );
    };

    return apiRequestFunc(START_LOAD, SUCCESS, ERROR, uniqueLogicFunc);
  }

  /**
   * モフトレチェックの歩行評価に関する詳細データを取得する、APIリクエスト
   *
   * @param {string} userId
   * @param {string} startDate
   * @param {string} endDate
   * @returns
   */
  public getMoffCheckDataAPI(userId: string, startDate: string, endDate: string, isGetOldestDate = false): any {
    const moff = MoffAPI.instance;
    if (moff === null) {
      return errorFunc(ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc: any = async () => {
      // ユーザー情報の取得
      const userDataMoff: any = await moff.getUserFunc(userId).then((response: any) => {
        return response.data;
      });
      // ユーザーユニークIDの取得
      const dataAppUser: any = await getAppUserData(userId, ERROR);
      // モフトレチェック、トレーニングデータの取得
      const apiResponseData: any[] = _.map(
        await this.getWalkingAbilityData(dataAppUser.data.unique_id, startDate, endDate),
        (el) => el.data,
      );
      let oldestDate: any;
      if (isGetOldestDate) {
        oldestDate = await this.getWalkingAbilityOldestData(dataAppUser.data.unique_id);
      } else {
        oldestDate = '';
      }
      // 取得したデータを全てまとめる
      return _.zipObject(
        ['user', 'tug', 'balance', 'cs30', 'oldestdate'],
        [userDataMoff, ...apiResponseData, oldestDate],
      );
    };

    return apiRequestFunc(START_LOAD, SUCCESS, ERROR, uniqueLogicFunc);
  }

  /**
   * モフトレチェックの詳細データを取得する、APIリクエスト
   *
   * @param {string} userId
   * @param {string[][]} startAndEndDateList
   * @returns
   */
  public getFreeChoiceMonthData(userId: string, startAndEndDateList: string[][]) {
    const moff = MoffAPI.instance;
    if (moff === null) {
      return errorFunc(ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc = async () => {
      let apiResponseData: any[] = [];
      // ユーザー情報の取得
      const userDataMoff = await moff.getUserFunc(userId).then((response: any) => response.data);
      // ユーザーユニークIDの取得
      const dataAppUser = await getAppUserData(userId, ERROR);

      apiResponseData = await Promise.all(
        _.map(startAndEndDateList, async (startAndEndDate: string) => {
          // モフトレチェック、トレーニングデータの取得
          return _.map(
            await this.getWalkingAbilityData(dataAppUser.data.unique_id, startAndEndDate[0], startAndEndDate[1]),
            (el) => el.data,
          );
        }),
      );
      const convertApiResponsedata = MoffCheckModule.convertApiResponsedata(apiResponseData);
      // 取得したデータを全てまとめる
      return _.zipObject(['user', 'tug', 'balance', 'cs30'], [userDataMoff, ...convertApiResponsedata]);
    };

    return apiRequestFunc(START_LOAD, SUCCESS, ERROR, uniqueLogicFunc);
  }

  /**
   * モフトレチェックの詳細データ（トレーニング開始月 + 指定月間）を取得する、APIリクエスト
   *
   * @param {string} userId
   * @param {string} startDate
   * @param {string} endDate
   * @returns
   */
  public getMoffCheckOldestMonthDataAPI(userId: string, startDate: string, endDate: string): any {
    const moff = MoffAPI.instance;
    if (moff === null) {
      return errorFunc(ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc: any = async () => {
      let apiResponseData: any[] = [];
      let apiResponseOldestData: any[] = [];
      // ユーザー情報の取得
      const userDataMoff: any = await moff.getUserFunc(userId).then((response: any) => response.data);
      // ユーザーユニークIDの取得
      const dataAppUser: any = await getAppUserData(userId, ERROR);

      // モフトレチェック、トレーニングデータの取得
      apiResponseData = _.map(
        await this.getWalkingAbilityData(dataAppUser.data.unique_id, startDate, endDate),
        (el) => el.data,
      );
      apiResponseOldestData = _.map(
        await this.getWalkingAbilityOldestData(dataAppUser.data.unique_id),
        (el) => el.data,
      );
      const apiResponseMerged = _.map(apiResponseData, (data, index) => {
        return {
          count: data.count + apiResponseOldestData[index].count,
          Items: [...data.Items, ...apiResponseOldestData[index].Items],
        };
      });
      // 取得したデータを全てまとめる
      return _.zipObject(['user', 'tug', 'balance', 'cs30'], [userDataMoff, ...apiResponseMerged]);
    };

    return apiRequestFunc(START_LOAD, SUCCESS, ERROR, uniqueLogicFunc);
  }

  /**
   * モフトレチェックの最新データの日付を取得するAPI
   *
   * @param {string} userId
   * @param {Function} addDisplayDate
   * @returns
   */
  public getMoffCheckLastDateAPI(userId: string, addDisplayDate: Function, intervalTimeMs = 500) {
    const uniqueLogicFunc = async () => {
      let latestDate: any = {};
      // ユーザーユニークIDの取得
      const dataAppUser: any = await getAppUserData(userId, ERROR);

      if (!dataAppUser) {
        // ユニークIDが存在しないユーザーはエラーにせず、空の要素を返す
        addDisplayDate({ user_id: userId, displayDate: '' });
        return '';
      } else {
        // モフトレチェック、トレーニング最新日の取得
        const apiResponseData: {
          type: MOFF_CHECK_API_PREFIX_LIST;
          data: UnionMoffCheckTrainingType | ROMTable[] | null;
        }[] = await Promise.all(
          _.map(
            [
              MOFF_CHECK_API_PREFIX_LIST.tug,
              MOFF_CHECK_API_PREFIX_LIST.balanceLeft,
              MOFF_CHECK_API_PREFIX_LIST.balanceRight,
              MOFF_CHECK_API_PREFIX_LIST.cs30,
              MOFF_CHECK_API_PREFIX_LIST.ss5,
              // ROMのみ肩屈曲・膝伸展、左右のそれぞれの最新データを一括で取得できる
              MOFF_CHECK_API_PREFIX_LIST.rom,
              MOFF_CHECK_API_PREFIX_LIST.gripLeft,
              MOFF_CHECK_API_PREFIX_LIST.gripRight,
              MOFF_CHECK_API_PREFIX_LIST.height,
              MOFF_CHECK_API_PREFIX_LIST.weight,
            ],
            async (type: MOFF_CHECK_API_PREFIX_LIST) => {
              const url = this.getMoffCheckCategoryURL(type.prefix, dataAppUser.data.unique_id, type.suffixLatest);
              const option = type.paramsOption ? { params: { measurement_type: type.paramsOption } } : undefined;
              // Promiseを返す
              return this.axiosInstance
                .get(url, option)
                .then((response: AxiosResponse<UnionMoffCheckTrainingType | ROMTable[]>) => ({
                  type,
                  data: response.data,
                }))
                .catch((error) => {
                  // 開眼立位の右足・左足の最新記録が存在しないユーザーはエラーにせず、nullを返す
                  if (error.response.status === 404) {
                    return {
                      type,
                      data: null,
                    };
                  }
                  // それ以外は普通のエラーを返す
                  throw error;
                });
            },
          ),
        );

        latestDate = MoffCheckModule.convertResponseToLatestDate(apiResponseData);

        latestDate === undefined
          ? addDisplayDate({ user_id: userId, displayDate: '' })
          : addDisplayDate({ user_id: userId, displayDate: latestDate });
        // 502エラーを防ぐためapiリクエストの間隔を開ける
        await waitTimeMs(intervalTimeMs);
        return latestDate;
      }
    };

    return apiRequestFunc(START_LOAD, FINISH, ERROR, uniqueLogicFunc);
  }

  // モフトレチェックtypeからURL変換
  private getMoffCheckCategoryURL(type: string, uniqueId: string, optionPrefix: string) {
    return `/check/${type}/${uniqueId}/records/${optionPrefix}`;
  }

  // tug・balance・cs30・ss5・rom・grip・heihgt・weightのデータ全てを取得する関数
  private async getAllMoffCheckData(uniqueId: string, startDate: string, endDate: string) {
    return Promise.all(
      _.map(
        [
          MOFF_CHECK_API_PREFIX_LIST.tug,
          MOFF_CHECK_API_PREFIX_LIST.balance,
          MOFF_CHECK_API_PREFIX_LIST.cs30,
          MOFF_CHECK_API_PREFIX_LIST.ss5,
          MOFF_CHECK_API_PREFIX_LIST.rom,
          MOFF_CHECK_API_PREFIX_LIST.gripRight,
          MOFF_CHECK_API_PREFIX_LIST.gripLeft,
          MOFF_CHECK_API_PREFIX_LIST.height,
          MOFF_CHECK_API_PREFIX_LIST.weight,
        ],
        async (type: MOFF_CHECK_API_PREFIX_LIST) => {
          const url = this.getMoffCheckCategoryURL(
            type.prefix,
            uniqueId,
            `${type.suffixDefault}from/${startDate}/to/${endDate}`,
          );
          // Promiseを返す
          return this.axiosInstance.get(url);
        },
      ),
    );
  }

  // tug・balance・cs30・ss5・rom・gripのトレーニング開始月のデータ全てを取得する関数
  private async getAllMoffCheckOldestData(uniqueId: string) {
    return Promise.all(
      _.map(
        [
          MOFF_CHECK_API_PREFIX_LIST.tug,
          MOFF_CHECK_API_PREFIX_LIST.balanceRight,
          MOFF_CHECK_API_PREFIX_LIST.cs30,
          MOFF_CHECK_API_PREFIX_LIST.ss5,
          MOFF_CHECK_API_PREFIX_LIST.rom,
          MOFF_CHECK_API_PREFIX_LIST.gripRight,
          MOFF_CHECK_API_PREFIX_LIST.gripLeft,
        ],
        async (type: MOFF_CHECK_API_PREFIX_LIST) => {
          const url = this.getMoffCheckCategoryURL(type.prefix, uniqueId, `${type.suffixDefault}oldest`);
          // Promiseを返す
          return this.axiosInstance.get(url);
        },
      ),
    );
  }

  // tug・balance・cs30のデータ全てを取得する関数
  private async getWalkingAbilityData(uniqueId: string, startDate: string, endDate: string) {
    return Promise.all(
      _.map(
        [MOFF_CHECK_API_PREFIX_LIST.tug, MOFF_CHECK_API_PREFIX_LIST.balanceRight, MOFF_CHECK_API_PREFIX_LIST.cs30],
        async (type: MOFF_CHECK_API_PREFIX_LIST) => {
          const url = this.getMoffCheckCategoryURL(type.prefix, uniqueId, `from/${startDate}/to/${endDate}`);
          // Promiseを返す
          return this.axiosInstance.get(url);
        },
      ),
    );
  }

  // tug・balance・cs30のトレーニング開始月のデータ全てを取得する関数
  private async getWalkingAbilityOldestData(uniqueId: string) {
    return Promise.all(
      _.map(
        [MOFF_CHECK_API_PREFIX_LIST.tug, MOFF_CHECK_API_PREFIX_LIST.balanceRight, MOFF_CHECK_API_PREFIX_LIST.cs30],
        async (type: MOFF_CHECK_API_PREFIX_LIST) => {
          const url = this.getMoffCheckCategoryURL(type.prefix, uniqueId, 'oldest');
          // Promiseを返す
          return this.axiosInstance.get(url);
        },
      ),
    );
  }
}

export const createMoffCheckAPIInstance: any = () => {
  // Moffインスタンスが存在しない場合、エラーアクションを返す
  return MoffCheck.instance === null
    ? [true, null, errorFunc(ERROR, '不明なエラーが発生しました.')]
    : [false, MoffCheck.instance, null];
};
