export const CSV_CREATE_START = 'CSV_CREATE/START';
export const CSV_CREATE_SUCCEED = 'CSV_CREATE/SUCCEED';
export const CSV_CREATE_FAIL = 'CSV_CREATE/FAIL';

export const UNKNOWN_ERROR = 'CSV_CREATE/UNKNOWN_ERROR';

export const CSV_FILE_DOWNLOAD_INTERVAL = 800;
export const DATA_CHECK_INTERVAL = 10;

export interface RequestParams {
  year: number;
  month: number;
  service_code: number;
}

export const CSV_OUTPUT_ITEMS = {
  interestCheck: {
    key: 'interestCheck',
    name: '興味関心',
    url: 'interest_check',
    downloadKey: 'interestCheckCSVUrl',
  },
  lifeCheck: {
    key: 'lifeCheck',
    name: '生活機能',
    url: 'life_check',
    downloadKey: 'lifeCheckCSVUrl',
  },
  functionalTrainingPlan: {
    key: 'functionalTrainingPlan',
    name: '個別機能訓練計画',
    url: 'functional_training_plan',
    downloadKey: 'functionalTrainingPlanCSVUrl',
  },
  barthelIndex: {
    key: 'barthelIndex',
    name: 'ADL維持等(BI)',
    url: 'barthel_index',
    downloadKey: 'barthelIndexCSVUrl',
  },
} as const;

export type CSV_OUTPUT_ITEMS = typeof CSV_OUTPUT_ITEMS[keyof typeof CSV_OUTPUT_ITEMS];

export const SERVICE_CODE = {
  dayCare: {
    id: 15,
    key: 'dayCare',
    name: '通所介護',
  },
  localBasedDayCare: {
    id: 78,
    key: 'localBasedDayCare',
    name: '地域密着型通所介護',
  },
} as const;

export type SERVICE_CODE = typeof SERVICE_CODE[keyof typeof SERVICE_CODE];

export const ADL_CODE = {
  first: {
    id: 1,
    key: 'first_month',
    name: '6ヶ月目まで',
  },
  seven: {
    id: 6,
    key: 'seven_month',
    name: '7ヶ月目以降',
  },
} as const;

export type ADL_CODE = typeof ADL_CODE[keyof typeof ADL_CODE];
